export default function ModalPopupComponent(props) {
  let styles = props ? props.modalCss : { "modal_container": "" };
  // document.querySelector("body").style.overflow =    true  ? "hidden" : "auto" ;
  return (
    <>
      {props.isPopup === true ? (
        <div className={styles.modal_overflow}> 
        <div className={styles.modal_container}>
          <div className={styles.modal_header}>
            {
              props?.showCross ?
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" onClick={props.closePopup}>
                <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M0.830,0.983 C0.313,1.037 -0.065,1.526 -0.015,2.074 C0.007,2.313 0.108,2.535 0.272,2.699 L6.646,9.486 L0.272,16.256 C-0.098,16.649 -0.098,17.285 0.272,17.677 C0.641,18.069 1.239,18.069 1.608,17.677 L1.608,17.677 L7.997,10.890 L14.372,17.677 C14.741,18.069 15.339,18.069 15.708,17.677 C16.077,17.285 16.077,16.649 15.708,16.256 L15.708,16.256 L9.319,9.486 L15.708,2.699 C16.081,2.316 16.092,1.683 15.731,1.287 C15.545,1.083 15.286,0.971 15.018,0.983 C14.774,0.989 14.543,1.095 14.372,1.279 L7.997,8.051 L1.608,1.279 C1.430,1.088 1.186,0.981 0.933,0.983 C0.898,0.981 0.864,0.981 0.830,0.983 Z"/>
                </svg> :
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" onClick={props.closePopup}>
          <path d="M13.3755 0.652573L7.5 6.52812L1.62445 0.652573C1.42102 0.449142 1.09168 0.449142 0.888772 0.652573L0.152573 1.38877C-0.0508576 1.5922 -0.0508576 1.92154 0.152573 2.12445L6.02812 8L0.152573 13.8755C-0.0508576 14.079 -0.0508576 14.4083 0.152573 14.6112L0.888772 15.3474C1.0922 15.5509 1.42154 15.5509 1.62445 15.3474L7.5 9.47188L13.3755 15.3474C13.579 15.5509 13.9083 15.5509 14.1112 15.3474L14.8474 14.6112C15.0509 14.4078 15.0509 14.0785 14.8474 13.8755L8.97188 8L14.8474 2.12445C15.0509 1.92102 15.0509 1.59168 14.8474 1.38877L14.1112 0.652573C13.9078 0.449142 13.5785 0.449142 13.3755 0.652573Z" fill="#666666"/>
        </svg>
          }

           {/* <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px">
                <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M0.830,0.983 C0.313,1.037 -0.065,1.526 -0.015,2.074 C0.007,2.313 0.108,2.535 0.272,2.699 L6.646,9.486 L0.272,16.256 C-0.098,16.649 -0.098,17.285 0.272,17.677 C0.641,18.069 1.239,18.069 1.608,17.677 L1.608,17.677 L7.997,10.890 L14.372,17.677 C14.741,18.069 15.339,18.069 15.708,17.677 C16.077,17.285 16.077,16.649 15.708,16.256 L15.708,16.256 L9.319,9.486 L15.708,2.699 C16.081,2.316 16.092,1.683 15.731,1.287 C15.545,1.083 15.286,0.971 15.018,0.983 C14.774,0.989 14.543,1.095 14.372,1.279 L7.997,8.051 L1.608,1.279 C1.430,1.088 1.186,0.981 0.933,0.983 C0.898,0.981 0.864,0.981 0.830,0.983 Z"/>
                </svg> */}

          <p className={styles.header_text}>{props.heading || ''}</p>

          </div>
              
                {props.children}
           {
            props.showBtn &&
           <button className={styles.applyNow} onClick={props.closePopup}>Apply Now</button>
           }
          </div>
        </div>
      ) : null}
    </>
  )
}