import React, { Component} from "react";

const childAgeList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ];

class PaxComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditRoom: false,
      selectedId:1,
    };
  }

  OnClickAddRooms = (id) => {
    this.setState({ selectedId :id });
  };

  render() {
  
    const styles = this.props.data?.styles;

    return (
      <>
        <div className={styles?.hotel_guestCont}>
        <div  className={styles?.select_full_section}
             onClick={() => this.props.data.onClickVisibilityStatus("rooms")}
     ></div>
          <div
            className={
              styles?.hotel_search_column +
              " " +
              styles?.hotel_search_accomodation
            }
          >
            {!this.props.data?.isMobile && 
            <div
              className={styles?.hotel_cabin_class_cont}
              // onClick={() => this.props.data.onClickVisibilityStatus("rooms")}
            >
              <div className={styles?.hotel_cabin_class_name}>
                <div
                  tabIndex="1"
                  onFocus={() => {
                    this.props.data.onInputFocus("rooms");
                  }}
                  className={styles?.adult_room_flex}
                >
                  <div className={styles?.adult_room_icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M9 9C9.77876 9 10.54 8.76907 11.1876 8.33641C11.8351 7.90376 12.3398 7.2888 12.6378 6.56932C12.9358 5.84983 13.0138 5.05813 12.8618 4.29433C12.7099 3.53053 12.3349 2.82894 11.7842 2.27827C11.2336 1.7276 10.532 1.35259 9.76817 1.20066C9.00437 1.04873 8.21267 1.12671 7.49318 1.42473C6.7737 1.72275 6.15875 2.22743 5.72609 2.87494C5.29343 3.52246 5.0625 4.28374 5.0625 5.0625C5.0625 6.10679 5.47734 7.10831 6.21577 7.84674C6.95419 8.58516 7.95571 9 9 9ZM9 10.125C6.55945 10.125 1.6875 11.6325 1.6875 14.625V16.875H16.3125V14.625C16.3125 11.6325 11.4405 10.125 9 10.125Z" fill="#666666"/>
                    </svg>
                  </div>
                  <span className={styles.adult_room_title}>
                    {this.props.data.state.selectedRoomDetails.rooms}{" "}
                    {this.props.data.state.selectedRoomDetails.rooms > 1
                      ? "Rooms"
                      : "Room"}
                    {", "}
                    {this.props.data.state.selectedRoomDetails.guest}{" "}
                    {this.props.data.state.selectedRoomDetails.guest > 1
                      ? " Guests"
                      : " Guest"}
                  </span>
                </div>
              </div>
            </div>}

            {(this.props.data?.isMobile || 
            this.props.data.state.openRoomDetailList === true ) ? (
              <div className={!this.props.data?.isMobile ? styles?.parent_autocomplete : ""}>
                <div className={`${styles?.hotel_room_list} ${!this.props.data?.isMobile ? styles?.child_autocomplete : ""}`}>
                  <div className={styles?.hotel_room_list_main}>
                    {this.props.data.state.roomList.map((element, index) => {
                      let roomLength = this.props.data.state.roomList.length;
                      return (
                        <div
                        className={styles?.hotel_room_detail_ele}
                        key={index}
                        >
                        <div className={styles?.hotel_room_head}>
                          <div className={styles?.hotel_roomWrap}>
                          <div className={styles?.hotel_room_title}>
                          Room {index + 1}
                          </div>
                          { element.id !== this.state.selectedId && (
                        <div className={styles?.hotel_room_traveller}>
                        
                        {element?.adults > 1 ? `${element?.adults} Adults`:`${element?.adults} Adult`}
                        {element?.children > 0 && (
                          element?.children > 1 ? `, ${element?.children} children`:`, ${element?.children} Child`)}
                        
                        </div>)}
                        </div>
                          {element.id !== this.state.selectedId ? (
                          <div className={styles?.hotel_room_container}>
                            <div
                            className={styles?.hotel_room_remove}
                            onClick={() => {
                              this.OnClickAddRooms(element.id);
                            }}
                            >
                            Edit
                            </div>
                            <div
                            className={styles?.hotel_room_edit}
                            onClick={() => {
                              this.props.data.removeRoomItem(roomLength);
                              const newSelectedId = roomLength > 1 ? this.props.data.state.roomList[0].id : 1;
                              this.setState({ selectedId: this.props.data.state.roomList[0].id }); 
                            }}
                            >
                            Remove
                            </div>
                          </div>
                          ) : null}
                        </div>
                  
                        
                        {element.id == this.state.selectedId && (
                          <div className={styles?.hotel_accomodation_main}>
                          <div className={styles?.hotel_adult_accomodation}>
                            <div className="hotel_adult_accomodation_tag">
                            <p>Adults</p>
                            <span>{this.props.data.adultLabel}</span>
                            </div>
                            <div className={styles.hotel_accomodation_conf}>
                            <span
                              className={
                              element.adultMinusCountDisable
                                ? styles.disbale_traveller +
                                " " +
                                styles.hotel_accomodation_icon
                                : styles.hotel_accomodation_icon
                              }
                              onClick={() =>
                              this.props.data.minusAccomodationCount(
                                element.id,
                                index,
                                "adult"
                              )
                              }
                            >
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              >
                              <path
                                d="M9 15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17L9 17C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15Z"
                                fill="black"
                              ></path>
                              </svg>
                            </span>
                            <div className={styles?.ac_count}>
                              {element.adults}
                            </div>
                            <span
                              className={
                              element.adultAddCountDisable
                                ? styles.disbale_traveller +
                                " " +
                                styles.hotel_accomodation_icon
                                : styles.hotel_accomodation_icon
                              }
                              onClick={() =>
                              this.props.data.addAccomodationCount(
                                element.id,
                                index,
                                "adult"
                              )
                              }
                            >
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              >
                              <path
                                d="M15 15V9C15 8.44772 15.4477 8 16 8C16.5523 8 17 8.44772 17 9V15L23 15C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17L17 17V23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23V17H9C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15H15Z"
                                fill="black"
                              />
                              </svg>
                            </span>
                            </div>
                          </div>
                          <div className={styles.hotel_children_accomodation}>
                            <div className={styles.children_accomodationTag}>
                            <p>Children</p>
                            <span>{this.props.data.childLabel}</span>
                            </div>
                            <div className={styles.hotel_accomodation_conf}>
                            <span
                              className={
                              element.childrenMinusCountDisable
                                ? styles.disbale_traveller +
                                " " +
                                styles.hotel_accomodation_icon
                                : styles.hotel_accomodation_icon
                              }
                              onClick={() =>
                              this.props.data.minusAccomodationCount(
                                element.id,
                                index,
                                "children"
                              )
                              }
                            >
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              >
                              <path
                                d="M9 15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17L9 17C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15Z"
                                fill="black"
                              ></path>
                              </svg>
                            </span>
                            <div className={styles?.ac_count}>
                              {element.children}
                            </div>
                            <span
                              className={
                              element.childrenAddCountDisable
                                ? styles.disbale_traveller +
                                " " +
                                styles.hotel_accomodation_icon
                                : styles.hotel_accomodation_icon
                              }
                              onClick={() =>
                              this.props.data.addAccomodationCount(
                                element.id,
                                index,
                                "children"
                              )
                              }
                            >
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              >
                              <path
                                d="M15 15V9C15 8.44772 15.4477 8 16 8C16.5523 8 17 8.44772 17 9V15L23 15C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17L17 17V23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23V17H9C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15H15Z"
                                fill="black"
                              />
                              </svg>
                            </span>
                            </div>
                          </div>
                          </div>
                        )}
                        {element.id == this.state.selectedId &&
                        <div
                          className={`${styles.child_rowMain_container} ${
                          !element.childDetails.length > 0
                            ? styles.child_rowMain_contSpace
                            : ""
                          }`}
                        >
                          {element.childDetails.length > 0 &&
                          element.childDetails.map((item, ind) => {
                            return (
                            <div
                              className={styles?.child_row_container}
                              key={ind}
                            >
                              <div className={styles?.child_row_main}>
                              <span className={styles?.child_age_wrap}>
                                <label className={styles?.child_age_lable}>
                                Child {ind + 1} Age
                                </label>
                                <div className={styles?.child_input_wrap}>
                                <input
                                  type="text"
                                  className={styles?.selected_age}
                                  placeholder="Select Age"
                                  value={item.age}
                                  onClick={() =>
                                  this.props.data.isChildrenAge(
                                    index,
                                    ind,
                                    "isOpen"
                                  )
                                  }
                                  readOnly
                                />
                                {item.isError === true ? (
                                  <span
                                  className={styles.err_message_text}
                                  >
                                  Please enter valid age
                                  </span>
                                ) : null}
                                <span
                                  className={
                                  item.isOpen === true
                                    ? styles.child_age_select_arrow
                                    : styles.child_age_select_arrow_rot
                                  }
                                  onClick={() =>
                                  this.props.data.isChildrenAge(
                                    index,
                                    ind,
                                    "isOpen"
                                  )
                                  }
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
  <path d="M1 0.5L6 5.5L11 0.5" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
                                </span>
                                </div>
                              </span>
                              {item.isOpen === true ? (
                                <div className={styles?.age_list_cont}>
                                <div className={styles.age_list_main}>
                                  {childAgeList.map((ele, i) => {
                                  return (
                                    <div
                                    key={i}
                                    className={styles.age_list_ele}
                                    onClick={() =>
                                      this.props.data.isChildrenAge(
                                      index,
                                      ind,
                                      "clickAge",
                                      ele
                                      )
                                    }
                                    >
                                    <span>{ele}</span>
                                    </div>
                                  );
                                  })}
                                </div>
                                </div>
                              ) : null}
                              </div>
                            </div>
                            );
                          })}
                        </div>}

                        {this.props.data.state.roomList.length < this.props.data?.totalRooms &&
                        index === this.props.data.state.roomList.length - 1 ? (
                          <div className={styles?.hotel_add_rooms_wrap}>
                          <div
                          className={styles?.hotel_add_rooms}
                          onClick={() => {
                            this.props.data.handlePaxComp();
                            this.OnClickAddRooms(this.props.data.state.roomList.length + 1);
                          }}
                          >
                          <div className={styles?.paxPlus_icon}>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            >
                            <g clip-path="url(#clip0_536_3897)">
                              <path
                              d="M7.31641 7.5V1.5C7.31641 0.947715 7.76412 0.5 8.31641 0.5C8.86869 0.5 9.31641 0.947715 9.31641 1.5V7.5L15.3164 7.5C15.8687 7.5 16.3164 7.94772 16.3164 8.5C16.3164 9.05229 15.8687 9.5 15.3164 9.5L9.31641 9.5V15.5C9.31641 16.0523 8.86869 16.5 8.31641 16.5C7.76412 16.5 7.31641 16.0523 7.31641 15.5V9.5H1.31641C0.764121 9.5 0.316406 9.05228 0.316406 8.5C0.316406 7.94771 0.764122 7.5 1.31641 7.5H7.31641Z"
                              fill="#FF6300"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_536_3897">
                              <rect
                                width="17"
                                height="16"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                              </clipPath>
                            </defs>
                            </svg>
                          </div>
                          <p>Add Room </p>
                          </div>
                          </div>
                        ) : null}
                        </div>
                      );
                    })}
                  
                  </div>
                  <div className={styles?.done_button_main}>
                    <input
                      // tabIndex="1"
                      value="Apply"
                      className={styles?.done_button}
                      readOnly
                      onClick={() => this.props.data.onClickDoneButton()}
                      onBlur={this.props.data.focusOutDone}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default PaxComp;
