export default function getFareType() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      client_data: localStorage.getItem("clientData") || "",
    },
  };
  return fetch("/shared/api/getFareType", requestOptions).then((response) =>
    response.json()
  );
}
