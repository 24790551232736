
import Theme8 from "./Theme8";
import commonFunction from "../../lib/utils/common";

export function PaxComp(props) {
  const { theme } = props;

  switch (theme) {

    case "theme8":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Theme8 data={props} />
      );
      break;

    default:
      return <Theme8 data={props} />;
  }
}

export default PaxComp;
