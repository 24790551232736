export const offlineCreateEnquiry = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch(
    "/shared/api/offline/enquiry/createEnquiry",
    requestOptions
  ).then((response) => response.json());
};

export const offlineGetEnquiry = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/offline/enquiry/getEnquiry", requestOptions).then(
    (response) => response.json()
  );
};

export const offlineDeleteEnquiry = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch(
    "/shared/api/offline/enquiry/updateEnquiry",
    requestOptions
  ).then((response) => response.json());
};

export const offlineSendEnquiry = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/offline/enquiry/sendEnquiry", requestOptions).then(
    (response) => response.json()
  );
};

export const offlineTraveller = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/offline/enquiry/addTraveller", requestOptions).then(
    (response) => response.json()
  );
};

export const offlineBook = (req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/offline/enquiry/bookEnquiry", requestOptions).then(
    (response) => response.json()
  );
};
